import '../styles/pages/robotics.scss';
import * as React from "react"
import {HeadFC, Link, navigate} from "gatsby"
import PageService from "../services/pageService";
import {useEffect, useState} from "react";
import {
    BigLogoImage, LoadingGif,
    robotLogo1,
    robotLogo2,
    robotLogo3,
    robotLogo4,
    robotLogo5,
    robot1,
    robot2,
    robot3,
    robot4,
    robot5
} from "../images";

import useAnalytics from "../hooks/useAnalytics";

const RobotPage = () => {
    const [isLoading, setLoading] = useState(true);
    const {initAnalytics, saveEmail} = useAnalytics();

    useEffect(() => {
        initAnalytics();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    // useEffect(() => {
    //     PageService.getCourseById(1).then((result) => {
    //         setData(result.data.data.attributes.Content)
    //     }).catch(() => {
    //     }).finally(() => {
    //         setLoading(false)
    //     });
    // }, []);

    return (
        <main>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }

            <section className="robotPage">
                <div className="robotPageHeader">
                    <div className="container">
                        <div className="robotPageHeaderLogo">
                            <img src={BigLogoImage} alt="" />
                        </div>
                        {/* <nav className="robotPageHeaderNav">
                            <ul>
                                <li>Block 1</li>
                                <li>Block 2</li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </nav> */}
                    </div>
                </div>
                <div className="robotPageBanner">
                    <video width="100%" height="100%" className="robotPageBannerVideo" preload="none" autoPlay muted loop playsInline>
                        <source src={'https://res.cloudinary.com/dvdl1ocba/video/upload/v1680951401/video-loop_w7jfwt.mp4'} type="video/mp4"/>
                    </video>
                    <div className="container robotPageBannerInside">
                        <h1 className="robotPageBannerTitle">
                        State-of-the-art wonder
                        </h1>
                        <p className="robotPageBannerText">
                            Our hyper-advanced robots will inspire awe wherever they are across the globe.
                        </p>
                    </div>
                </div>

                <div className="robotPageAbout grey">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            <div className="robotPageAboutInfo left">
                                <img src={robotLogo1} alt="" className="robotPageAboutIcon" />
                                <p className="robotPageAboutText">Multiply the power of artificial Intelligence with an artificial body. Ameca is the physical presence that brings your code to life. The most advanced lifelike humanoid you can use to develop and show off your greatest machine learning interactions. This robot is the digital interface to the real world.</p>
                                <p className="robotPageAboutText">Ameca is not just a development tool, it’s a cutting-edge integration of the latest technology that’s built to last. Grab attention and fuel the imagination with Ameca.</p>
                                <Link to='/mraeer' className="robotPageAboutBtn">Mr. AEER</Link>
                            </div>
                            <img src={robot1} alt="" className="robotPageAboutImage" />
                        </div>
                    </div>
                </div>

                <div className="robotPageAbout white">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            <img src={robot2} alt="" className="robotPageAboutImage" />
                            <div className="robotPageAboutInfo right">
                                <img src={robotLogo2} alt="" className="robotPageAboutIcon" />
                                <p className="robotPageAboutText">Humans, check this out, Mesmer has found a way to mirror you. They’re mesmerising by design and can imitate the appearance of just about anybody. Able to maintain eye contact and respond in real time to those around them, Mesmer makes for robot-human interactions that will never be forgotten. </p>
                                <p className="robotPageAboutText">Every Mesmer component is designed and made by our team to work in perfect alignment, keeping their movement convincing and their controls simple to use.</p>
                                <Link to='/mraeer' className="robotPageAboutBtn">Mr. AEER</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="robotPageAbout grey">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            <div className="robotPageAboutInfo left">
                                <img src={robotLogo3} alt="" className="robotPageAboutIcon" />
                                <p className="robotPageAboutText">With the most extraordinary assistant you could ever hope to meet, Quinn turns the standard customer service routine into a wondrous experience. Our smart, personable robot is a true professional, innovatively designed to handle conversations in bustling, noisy environments.</p>
                                <p className="robotPageAboutText">With no special set up needed, Quinn’s tech does the hard work for you. Add them to your front desk or information point to delight all who arrive there.</p>
                                <Link to='/mraeer' className="robotPageAboutBtn">Mr. AEER</Link>
                            </div>
                            <img src={robot3} alt="" className="robotPageAboutImage" />
                        </div>
                    </div>
                </div>

                <div className="robotPageAbout white">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            <img src={robot4} alt="" className="robotPageAboutImage" />
                            <div className="robotPageAboutInfo right">
                                <img src={robotLogo4} alt="" className="robotPageAboutIcon" />
                                <p className="robotPageAboutText">The finest actor around and the most charming of robots, RoboThespian’s innovative engineering makes them capable of assuming any personality and enchanting people in almost any setting: exhibitions, science centres, theatres, stadiums, corporate presentations and live TV. </p>
                                <p className="robotPageAboutText">Fully interactive, multi-lingual and simple to set up, RoboThespian has made a lasting impression at events and institutions around the world.</p>
                                <Link to='/mraeer' className="robotPageAboutBtn">Mr. AEER</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="robotPageAbout grey">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            <div className="robotPageAboutInfo left">
                                <img src={robotLogo5} alt="" className="robotPageAboutIcon" />
                                <p className="robotPageAboutText">Sometimes a unique experience needs a unique robot. We can create everything from realistic humanoid robots who are at home on a film set to larger-than-life monsters that stun visitors at a multinational attraction.</p>
                                <p className="robotPageAboutText">Our expert team has been creating robotic commissions since 2006, establishing a technical ecosystem that can quickly bring your ideas to life.</p>
                                <Link to='/mraeer' className="robotPageAboutBtn">Mr. AEER</Link>
                            </div>
                            <img src={robot5} alt="" className="robotPageAboutImage" />
                        </div>
                    </div>
                </div>

            </section>
            
        </main>
    )
}

export default RobotPage

export const Head: HeadFC = () => <title>AEER Platform</title>
